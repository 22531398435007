import React, {Component} from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, withIonLifeCycle  } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Tab2.css';
import ReactPlayer from 'react-player'
import {Form, FormGroup, Input, Label, Button} from "reactstrap";
import axios from "axios";
import Modal from 'react-modal';



var link;
var orgName;

const checkInButton = {

    display: "inline-block",

    backgroundColor: "Transparent",


}

const imageSize = {

    height: "35px",
    width: "35px",



}

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

class Tab2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stateChanger: "",
            playing: true,
            showModal: false,
            name: "",
            email: "",
            message: "",
            reload: "reload",
            refreshmessage: ""


        };

    }

    ionViewWillLeave() {
        console.log('ionViewWillLeave event fired')
        this.setState({playing:false})

        console.log("LEAVING TAB")
    }



    componentDidMount() {

       link = localStorage.getItem('link');

console.log(link)

         // this.setState({stateChanger: "abc"})
        var self = this;
        setTimeout(
            function() {
               self.checkPlayerStatus();
               //10 minutes
            }, 1000 * 60 * 10);

    }


checkPlayerStatus = () =>{

        var streamIDCode;

        try {
            streamIDCode = localStorage.getItem('idcode');

        }

        catch (e) {

            console.log("Can't find a stream code");

        }



        if(this.state.playing === true) {

            //after 10 minutes call analytics server
            console.log("hanging in there")

            axios.post('https://analytics.relayfaith.com/stats', {
                orgID: streamIDCode
            })
                .then(function (response) {
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error);
                });


        }


}

closModal = () => {

    this.setState({showModal: false})


}

postMessage = () => {

    let streamIDCode = localStorage.getItem('idcode');
/*
    axios.post('https://messaging.relayfaith.com/message', JSON.stringify({
        orgID : streamIDCode,
        name: this.state.name,
        email: this.state.email,
        message: this.state.message,
        checkCode : "8573948594388833"
    }))
        .then(function (response) {

            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        });
    this.setState({showModal: false})
*/
    axios({
        method: 'post',
        crossDomain: true,
        url: 'https://messaging.relayfaith.com/messageax',
        data: JSON.stringify({
            orgID : streamIDCode,
            name: this.state.name,
            email: this.state.email,
            message: this.state.message,
            checkCode : "8573948594388833"
        })
    }).then(function (response) {

        console.log(response);
    })
        .catch(function (error) {
            console.log(error);
        });

    this.setState({showModal: false})

}

handleNoStream = (e) => {

        console.log("error with player")
        console.log(e);

       // this.setState({refreshMessage: "Stream hasn't started or connection issue. Try refreshing the page."})


}

    render() {


      //  console.log(link)




        return(

            <div >
                <IonPage>

                    <IonHeader>
                        <IonToolbar >

                            {/* <IonTitle style={{display: "inline-block"}}>Relay Faith</IonTitle> */ }
                        <center>    <button style={checkInButton} onClick={() => this.setState({showModal:true})} ><img style={imageSize} src='../assets/icon/checkin.svg'/><b>Check In</b></button>
                            <button style={checkInButton} onClick={() => window.location.reload(false) } ><img style={imageSize} src='../assets/icon/refresh.svg'/><b>Refresh</b></button> </center>

                        </IonToolbar>
                    </IonHeader>
                    <IonContent>




                        <div  style={{'width':'75%', 'height': '75%',display: 'flex', justifyContent: 'center'}}>
                            <ReactPlayer className='react-player' url={link} onError={e => this.handleNoStream(e)} playing={this.state.playing} file='forceHLS' controls="true" width="100%" height="100%" />

                        </div>

                    </IonContent>
                    <Modal
                        isOpen={this.state.showModal}
                        contentLabel="Minimal Modal Example" style={customStyles}
                    >

                        <Form>
                            <FormGroup>
                                <b style={{paddingBottom: "5px"}}>Let your church know you are watching. </b>

                            </FormGroup>
                            <FormGroup>
                                <Input type="name" name="name" id="exampleName" placeholder="Name" onChange={(e) => this.setState({name:e.target.value})} />
                            </FormGroup>
                            <FormGroup>
                                <Input type="email" name="email" id="exampleEmail" placeholder="Email" onChange={(e) => this.setState({email:e.target.value})}/>
                            </FormGroup>

                            <FormGroup>
                                <Label for="exampleText">(Optional) Message or Prayer Request</Label>
                                <Input type="textarea" name="text" id="exampleText" onChange={(e) => this.setState({message:e.target.value})} />
                            </FormGroup>
                            <Button onClick={() => this.closModal()}>Cancel</Button>
                            <Button style={{marginLeft:"125px"}} onClick={() => this.postMessage()}>Submit</Button>
                        </Form>

                    </Modal>
                </IonPage>
            </div>
        )
            ;

    }
}


export default withIonLifeCycle(Tab2);
